@use '@/styles/utils/mixins.scss' as *;

.educationalArticlesWrapper {
  display: flex;
  flex-direction: column;
  gap: 6.25rem;
  @include tab() {
    gap: 3.75rem;
  }
  .sectionHeadingContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    align-items: last baseline;

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }
    .sectionLeftContent {
      grid-column: span 2;
      @include tab() {
        grid-column: span 1;
      }
    }
  }
  .viewArchivesLink {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-left: auto;
    width: max-content;

    @include tab() {
      margin-left: 0;
    }
  }
  .mediaList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    @include tab() {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 -1.875rem;
      width: calc(100% + 3.75rem);
    }
    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .customPagination {
    margin-left: auto;
    :global {
      .ant-pagination-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 2rem;
        height: 2rem;
        margin-inline-end: 0.5rem;

        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 0.5rem;
        outline: 0;
        cursor: pointer;
        user-select: none;
        color: $dark;
        transition: all 0.2s ease-in-out;
      }
      .ant-pagination-item-active {
        background-color: $primary;
        a {
          color: $white;
          &:hover,
          &:active,
          &:focus {
            color: $white;
          }
        }
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        color: $dark;
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
        &.ant-pagination-disabled {
          color: #d7d7d7;
        }
      }
      .ant-pagination-prev {
        margin-right: 1.5rem;
      }
      .ant-pagination-next {
        margin-left: 1.5rem;
      }
    }
  }
}
